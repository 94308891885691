import "./App.css";
import React, { Suspense, lazy, Fragment, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import themeFile from "./utility/theme";
import LinearProgress from "@mui/material/LinearProgress";
import logo from "../src/images/icon.png";
//import { configureDataStore } from "./dataStoreConfig";

// Lazy-loaded components
const Home = lazy(() => import("./components/Home"));
const Signin = lazy(() => import("./components/Signin"));
const Signup = lazy(() => import("./components/Signup"));
const ForgotPassword = lazy(() => import("./components/ForgotPassword"));
const CheckCategory = lazy(() => import("./components/CheckCat"));
const CheckManager = lazy(() => import("./components/CheckManager"));
const CheckResident = lazy(() => import("./components/CheckResident"));
const CheckSecurity = lazy(() => import("./components/CheckSecurity"));
const Onboarding = lazy(() => import("./components/Onboarding"));
const ManagerDetails = lazy(() => import("./components/ManagerDetails"));
const CommunityDetails = lazy(() => import("./components/CommunityDetails"));
const ResidentDetails = lazy(() => import("./components/ResidentDetails"));
const VisitorManagement = lazy(() => import("./components/VisitorManagement"));
const SecurityDetails = lazy(() => import("./components/SecurityDetails"));
const CheckHouse = lazy(() => import("./components/CheckHouse"));
const HouseCheck = lazy(() => import("./components/HouseCheck"));
const TenantDetails = lazy(() => import("./components/TenantDetails"));
const HouseDetails = lazy(() => import("./components/HouseDetails"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const FeeManagement = lazy(() => import("./components/FeeManagement"));
const Transactions = lazy(() => import("./components/Transactions"));
const Settings = lazy(() => import("./components/Settings"));
const Utility = lazy(() => import("./components/Utility"));
const BuyPower = lazy(() => import("./components/BuyPower"));
const Tvsubscription = lazy(() => import("./components/Tvsubscription"));
const Features = lazy(() => import("./components/Features"));
const Products = lazy(() => import("./components/Products"));
const Pricing = lazy(() => import("./components/Pricing"));
const About = lazy(() => import("./components/About"));
const FAQs = lazy(() => import("./components/FAQs"));
const Contacts = lazy(() => import("./components/Contacts"));
const Terms = lazy(() => import("./components/Terms"));
const TermsMobile = lazy(() => import("./components/TermsMobile"));
const PayoutPage = lazy(() => import("./components/PayoutPage"));
const PropertyTerms = lazy(() => import("./components/PropertyTerms"));
const MessagePage = lazy(() => import("./components/MessagePage"));
const Structure = lazy(() => import("./components/Structure"));
const VisitorRequesting = lazy(() => import("./components/VisitorRequesting"));
const VisitorDetails = lazy(() => import("./components/VisitorDetails"));
const Backend = lazy(() => import("./components/Backend"));
const Facilities = lazy(() => import("./components/Facilities"));
const ProcessPayments = lazy(() => import("./components/ProcessPayments"));
const UserCategory = lazy(() => import("./components/UserCategory"));
const BroadcastMessage = lazy(() => import("./components/BroadcastMessage"));
const UtilityRecharge = lazy(() => import("./components/UtilityRechare"));
const UtilityAnalytics = lazy(() => import("./components/UtilityAnalytics"));
const ExpenseManagement = lazy(() => import("./components/ExpenseManagement"));
const TaskManagement = lazy(() => import("./components/TaskManagement"));
const BuyWater = lazy(() => import("./components/BuyWater"));
const UtilityPayment = lazy(() => import("./components/UtilityPayment"));
const AppSupport = lazy(() => import("./components/AppSupport"));
const CommunityManagement = lazy(() =>
  import("./components/CommunityManagement")
);
const ExternalCardManagement = lazy(() =>
  import("./components/ExternalCardManagement")
);
const SubaccountManagement = lazy(() =>
  import("./components/SubaccountManagement")
);
const PropertyManagement = lazy(() =>
  import("./components/PropertyManagement")
);
const ResidentManagement = lazy(() =>
  import("./components/ResidentManagement")
);
const SecurityManagement = lazy(() =>
  import("./components/SecurityManagement")
);

//Components
const theme = createTheme(themeFile);

const SuspenseWithTimeout = ({ fallback, timeout, children }) => {
  const [timedOut, setTimedOut] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    //configureDataStore();
    const timer = setTimeout(() => {
      if (!loaded) {
        setTimedOut(true);
        window.location.reload();
      }
    }, timeout);

    return () => clearTimeout(timer);
  }, [timeout, loaded]);

  const handleLoad = () => {
    setLoaded(true);
  };

  return (
    <Suspense fallback={<div onLoad={handleLoad}>{fallback}</div>}>
      {children}
    </Suspense>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="container">
          <SuspenseWithTimeout
            fallback={
              <Fragment>
                <Grid
                  container
                  alignItems="center"
                  style={{ minHeight: "75vh" }}
                >
                  <Grid item sm={4} xs={12}></Grid>
                  <Grid item sm={4} xs={12} style={{ textAlign: "center" }}>
                    <img src={logo} style={{ height: 80 }} alt="loading" />
                    <LinearProgress
                      color="secondary"
                      style={{ width: "100%", marginTop: 10 }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}></Grid>
                </Grid>
              </Fragment>
            }
            timeout={10000}
          >
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/appsupport" element={<AppSupport />} />
              <Route exact path="/backend" element={<Backend />} />
              <Route exact path="/facilities" element={<Facilities />} />
              <Route exact path="/signin" element={<Signin />} />
              <Route exact path="/signup" element={<Signup />} />
              <Route exact path="/usercategory" element={<UserCategory />} />
              <Route exact path="/propertyterms" element={<PropertyTerms />} />
              <Route exact path="/features" element={<Features />} />
              <Route exact path="/products" element={<Products />} />
              <Route exact path="/pricing" element={<Pricing />} />
              <Route exact path="/faq" element={<FAQs />} />
              <Route exact path="/contact" element={<Contacts />} />
              <Route exact path="/terms" element={<Terms />} />
              <Route exact path="/termsmobile" element={<TermsMobile />} />
              <Route
                exact
                path="/subaccountManagement"
                element={<SubaccountManagement />}
              />
              <Route
                exact
                path="/forgotpassword"
                element={<ForgotPassword />}
              />
              <Route exact path="/checkcategory" element={<CheckCategory />} />
              <Route exact path="/checkmanager" element={<CheckManager />} />
              <Route exact path="/checkresident" element={<CheckResident />} />
              <Route exact path="/pricingstructure" element={<Structure />} />
              <Route exact path="/checksecurity" element={<CheckSecurity />} />
              <Route exact path="/onboarding" element={<Onboarding />} />
              <Route
                exact
                path="/communitymanagement"
                element={<CommunityManagement />}
              />
              <Route
                exact
                path="/managerdetails"
                element={<ManagerDetails />}
              />
              <Route exact path="/checkhouse" element={<CheckHouse />} />
              <Route exact path="/housecheck" element={<HouseCheck />} />
              <Route exact path="/housedetails" element={<HouseDetails />} />
              <Route exact path="/tenantdetails" element={<TenantDetails />} />
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/invoices" element={<FeeManagement />} />
              <Route exact path="/transactions" element={<Transactions />} />
              <Route exact path="/settings" element={<Settings />} />
              <Route exact path="/utility" element={<Utility />} />

              <Route exact path="/buypower" element={<BuyPower />} />
              <Route exact path="/buywater" element={<BuyWater />} />
              <Route
                exact
                path="/tvsubscription"
                element={<Tvsubscription />}
              />
              <Route exact path="/payouts" element={<PayoutPage />} />
              <Route exact path="/messages" element={<MessagePage />} />
              <Route
                exact
                path="/visitordetails"
                element={<VisitorDetails />}
              />
              <Route exact path="/signup/:propertyId" element={<Signup />} />
              <Route
                exact
                path="/visitrequest"
                element={<VisitorRequesting />}
              />
              <Route
                exact
                path="/utilitypayment"
                element={<UtilityPayment />}
              />
              <Route
                exact
                path="/utilityanalytics"
                element={<UtilityAnalytics />}
              />
              <Route
                exact
                path="/expensemanagement"
                element={<ExpenseManagement />}
              />
              <Route
                exact
                path="/taskmanagement"
                element={<TaskManagement />}
              />
              <Route
                exact
                path="/utilityrecharge"
                element={<UtilityRecharge />}
              />
              <Route
                exact
                path="/broadcastmessage"
                element={<BroadcastMessage />}
              />
              <Route
                exact
                path="/processpayments/:id"
                element={<ProcessPayments />}
              />
              <Route
                exact
                path="/visitrequest"
                element={<VisitorRequesting />}
              />

              <Route
                exact
                path="/signup/:propertyId/:houseId"
                element={<Signup />}
              />

              <Route exact path="/transactions" element={<Transactions />} />
              <Route exact path="/invoices" element={<FeeManagement />} />
              <Route
                exact
                path="/visitormanagement"
                element={<VisitorManagement />}
              />
              <Route
                exact
                path="/securitymanagement"
                element={<SecurityManagement />}
              />
              <Route
                exact
                path="/visitormanagement"
                element={<VisitorManagement />}
              />
              <Route
                exact
                path="/securitydetails"
                element={<SecurityDetails />}
              />
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route
                exact
                path="/propertymangement"
                element={<PropertyManagement />}
              />
              <Route
                exact
                path="/externalcardmanagement"
                element={<ExternalCardManagement />}
              />
              <Route
                exact
                path="/residentdetails"
                element={<ResidentDetails />}
              />
              <Route
                exact
                path="/communitydetails"
                element={<CommunityDetails />}
              />
              <Route
                exact
                path="/residentmanagement"
                element={<ResidentManagement />}
              />
            </Routes>
          </SuspenseWithTimeout>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
